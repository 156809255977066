<template>
  <div class="notifyWizard">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <div
        id="m_wizard"
        class="m-wizard m-wizard--2 m-wizard--success m-wizard--step-first"
      >
        <NotifyWizardNav
          :first-step="checkActive(0)"
          :second-step="checkActive(1) || checkActive(3)"
          :third-step="checkActive(2)"
        />

        <div class="m-wizard__form">
          <div class="m-portlet__body">
            <template v-if="checkActive(0) && notificationMedia">
              <label>{{ 'Notify over' }}</label>
              <select
                v-model="notifyRequest.notificationMedia"
                class="mb-4 form-control col-2"
              >
                <option
                  v-for="(media, i) in notificationMedia"
                  :key="`mediaType${i}`"
                  :value="media"
                >
                  {{ media }}
                </option>
              </select>
            </template>
            
            <NotifyWizardStepZero />

            <NotifyWizardStepOne />

            <NotifyWizardStepTwo />

            <!-- BUTTONS -->
            <div class="text-center">
              <router-link
                v-if="checkActive(0)"
                :to="`/notify/custom/${ installationId }/${ recipientId }`"
                class="btn btn-sm btn-primary mr-2 float-left"
                @click="active = 3"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="envelope"
                  style="vertical-align: middle;"
                />
                {{ $t('customMessage') }}
              </router-link>
              <template v-if="noTranslationAvailable">
                <p class="red">
                  There is no translation in the required language ({{ notifyRequest.language }}) for this template ({{ notifyRequest.originalMessage }}).
                </p>
                <router-link
                  :to="`/smsmanagement/template/${ notifyRequest.template }`"
                  class="btn btn-sm btn-primary"
                >
                  Go to Message Management
                </router-link>
              </template>
              <template v-else>
                <!-- BACK BUTTON -->
                <a
                  v-if="active == 1"
                  href="#"
                  class="btn btn-sm btn-metal mr-2"
                  @click.prevent="stepTowards('back')"
                ><font-awesome-icon
                  class="mr-2"
                  icon="arrow-left"
                  style="vertical-align: middle;"
                />{{ $t('back') }}</a>
                <!-- SEND BUTTON -->
                <a
                  v-if="!noTranslationAvailable && checkActive(1)"
                  href="#"
                  class="btn btn-sm btn-primary mr-2"
                  @click.prevent="stepTowards('next'), sendMessage()"
                >
                  <font-awesome-icon
                    class="mr-1"
                    icon="check"
                  />
                  <span>
                    {{ $t('send') }}
                  </span>
                </a>
                <button
                  v-else-if="checkActive(1)"
                  class="btn btn-sm btn-primary cursor-not-allowed mr-2"
                  disabled
                >
                  <font-awesome-icon
                    class="mr-1"
                    icon="check"
                  />
                  <span>
                    {{ $t('send') }}
                  </span>
                </button>
                <!-- NEXT BUTTON -->
                <!-- <a
                    v-if="displayNextButton"
                    href="#"
                    class="btn btn-primary btn-block mt-4"
                    @click.prevent="stepTowards('next')"><i class="fas fa-arrow-right mr-2"/>Next</a> -->
              </template>
              <div class="clearfix" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { urlParseMixin } from '@/mixins/urlParseMixin.js';
import { mapGetters } from 'vuex';

import historyAdapter from '@/mixins/historyAdapter.js';
import jiraAdapter from '@/mixins/jiraAdapter.js';
export default {
  name: 'NotifyWizard',
  components: {
    NotifyWizardNav: () => import('@/components/NotifyWizard/NotifyWizardNav.vue'),
    NotifyWizardStepZero: () => import('@/components/NotifyWizard/NotifyWizardStepZero.vue'),
    NotifyWizardStepOne: () => import('@/components/NotifyWizard/NotifyWizardStepOne.vue'),
    NotifyWizardStepTwo: () => import('@/components/NotifyWizard/NotifyWizardStepTwo.vue')
  },
  mixins: [
    urlParseMixin,
    jiraAdapter,
    historyAdapter
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    step: {
      type: String,
      required: false,
      default () {
        return '1';
      }
    },
    recipientId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      notifyRequest: {
        number: null,
        message: null,
        originalMessage: null,
        template: null,
        recipient: null,
        language: null,
        notificationMedia: null,
        email: null
      },
      loading: true,
      notifyTemplateList: null,
      active: 0,
      installationType: null,
      filter: null,
      noTranslationAvailable: false,
      languageAvailable: false,
      templatesAvailable: false,
      sendingWasSuccessful: null,
      notificationMedia: null
    }
  },
  computed: {
    ...mapGetters([
      'store_getJiraUrl'
    ]),
    filterByTitle () {
      if (!this.filter) {
        return this.notifyTemplateList;
      }
      return this.notifyTemplateList.filter(template => template.name.toLowerCase().includes(this.filter.toLowerCase()));
    },
    displayNextButton: function () {
      if (this.active == 2) {
        return false;
      }
      if (this.notifyRequest == null) {
        return false;
      }
      if (this.notifyRequest.number == null && this.notifyRequest.email == null) {
        return false;
      }
      if (this.notifyRequest.template == null) {
        return false;
      }
      return true;
    }
  },
  mounted () {
    this.getAllTemplates();
    if (this.installationId) {
      this.getContact();
    }
  },
  methods: {
    setLoading () {
      window.setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    jiraUrl (endpoint) {
      return this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${ endpoint }`);
    },
    checkActive (intToCheck) {
      return this.active == intToCheck;
    },
    setStep (step) {
      this.active = step;
    },
    sendMessage () {
      this.axios.post(`/SmsGateway/SendMessage?contactPersonId=${ this.recipientId }&templateId=${ this.notifyRequest.template }&installationId=${ this.installationId }&media=${ this.notifyRequest.notificationMedia }`)
      .then((response) => {
        if(response.status == 200) {
          var jiraMessage = "";
          var historyMessage = "";
          var notificationRecipient = null;

          if(this.notifyRequest.notificationMedia == 'SMS') {
            jiraMessage = "\\\\ Recipient: " + this.notifyRequest.recipient + "; Number: " + this.notifyRequest.number + " \\\\ Message: " + response.data;
            historyMessage = "Recipient: " + this.notifyRequest.recipient + "; Number: " + this.notifyRequest.number + "; Message: " + response.data;
            notificationRecipient = this.notifyRequest.number;
          }
          else if(this.notifyRequest.notificationMedia == 'Email') {
            jiraMessage = "\\\\ Recipient: " + this.notifyRequest.recipient + "; Email: " + this.notifyRequest.email + " \\\\ Message: " + response.data;
            historyMessage = "Recipient: " + this.notifyRequest.recipient + "; Email: " + this.notifyRequest.email + "; Message: " + response.data;
            notificationRecipient = this.notifyRequest.email;
          }

          let jiraRequest = this.sendJiraComment(this.installationId, jiraMessage);
          let historyRequest = this.sendHistoryComment(this.installationId, historyMessage, this.notifyRequest.notificationMedia, notificationRecipient);

          this.axios.all([ jiraRequest, historyRequest ])
            .then(this.axios.spread((jiraResponse, historyResponse) => {
              if (jiraResponse == null || historyResponse == null) {
                this.sendingWasSuccessful = false;
                return;
              }
              if (jiraResponse.status == null || historyResponse.status == null) {
                this.sendingWasSuccessful = false;
                return;
              }
              if (jiraResponse.status != 200 || historyResponse.status != 200) {
                this.sendingWasSuccessful = false;
                return;
              }
              this.sendingWasSuccessful = true;
            }))
            .catch(() => {
              this.sendingWasSuccessful = false;
            })
            .finally(() => {
              this.setLoading();
              if (this.sendingWasSuccessful == true) {
                window.setTimeout(() => {
                  this.$router.push(`/installation/${ this.installationId }`)
                }, 2500);
              }
            });
        }
      });
    },
    getAllTemplates () {
      this.axios.get('/Installation/GetInstallationTypeById?installationId=' + this.installationId)
        .then((response) => {
          this.installationType = response.data;
          this.axios.get('/SmsManagement/GetTemplatesByInstallationTypeId?installationTypeId=' + this.installationType.id)
            .then((response) => {
              if (response == null) {
                return;
              }
              if (response.data == null) {
                return;
              }
              if (response.data.length == null) {
                return;
              }
              if (response.data.length == 0) {
                return;
              }
              this.notifyTemplateList = response.data;
              this.templatesAvailable = true;
            });
        })
        .finally(() => {
          this.setLoading();
        });
    },
    getContact () {
      this.axios.get('/Contact/ContactById', { params: { 'contactId': this.recipientId }})
        .then((response) => {
          if (response && response.data && response.data.phone) {
            this.notifyRequest.number = response.data.phone;
          }
          if (response && response.data && response.data.email) {
            this.notifyRequest.email = response.data.email;
          }
          if ((response && response.data && response.data.firstname) && (response && response.data && response.data.surname)) {
            this.notifyRequest.recipient = response.data.firstname + ' ' + response.data.surname;
          }
          if (response && response.data && response.data.language) {
            this.notifyRequest.language = response.data.languageCode[0];
            this.languageAvailable = true;
          }
          this.notificationMedia = [];
          if(this.notifyRequest.number) {
            this.notificationMedia.push('SMS');
            this.notifyRequest.notificationMedia = this.notificationMedia[0];
          }
          if(this.notifyRequest.email) {
            this.notificationMedia.push(('Email'));
            this.notifyRequest.notificationMedia = this.notificationMedia[0];
          }
        })
        .finally(() => {
          this.setLoading();
        });
    },
    getMessage (templateTmp, languageTmp) {
      this.loading = true;
      this.axios.get(`/SmsManagement/GetMessageByLanguage?templateId=${ templateTmp }&primaryLanguage=${ languageTmp }&installationId=${ this.installationId }`)
        .then((response) => {
          if (!response || response.status != 200) {
            this.noTranslationAvailable = true;
          } else {
            this.notifyRequest.message = response.data.message;
            this.notifyRequest.subject = response.data.subject;
          }
        })
        .finally(() => {
          this.setLoading();
        });
    },
    stepTowards (direction) {
      if (direction === "next") {
        if (this.active < 2) {
          this.active++;
        }
      } else {
        if (this.active > 0) {
          this.active--;
        }
      }
    },
    // GET VALUE FROM SELECTED ELEMENT
    setCurrent (selectedTemplate) {
      this.getMessage(selectedTemplate.id, this.notifyRequest.language);
      this.notifyRequest.template = selectedTemplate.id;
      this.notifyRequest.originalMessage = selectedTemplate.name;
      this.stepTowards('next');
    }
  }
}
</script>

<style scoped>
.form-control.btn.btn-secondary {
  text-align: left;
}
.loaderWrapper {
  margin: auto;
  text-align: center;
}
.m-loader {
  width: 100%;
  display: inline-block;
}
.m-loader::before {
  width: 5rem;
  height: 5rem;
  margin-left: -2.5rem;
}
.lead.m--font-info {
  padding-top: 5rem;
}
</style>
